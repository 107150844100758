module.exports = {
  siteTitle: 'Instant Touch® - Beautiful Software for your cosmetic clinic', // <title>
  manifestName: 'InstantTouch',
  manifestShortName: 'Instant', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/instant/`, // This path is subpath of your hosting https://domain/portfolio
  
  mainTitle:'Beautiful Software',
  subTitle: 'FOR YOUR COSMETIC CLINIC',
  btnTitle:'Book Demo',
  copyRight: 'Instant Touch® brought to you by Instant Consult™ 2018-2023',
  bottomTitle: 'Redirecting you to our homepage',
  policyLink: 'https://instant-touch-live.s3.amazonaws.com/documents/1698361730201Instant%20Touch%20Privacy%20Policy.pdf',
  seo_content: 'instant touch, beautiful software, for your cosmetic clinic',
  seo_description: 'Beautiful Software for your cosmetic clinic',

  // features
  features: [
    {
      img: 'icon/LOGINS.png',
      ftitle: 'LOGINS',
      fcontent: 'Personalised logins for your cosmetic nurses, prescribing doctors, and clinic administrators with various permission settings'
    },
    {
      img: 'icon/CLINICS.png',
      ftitle: 'CLINICS',
      fcontent: 'Register one or hundreds of clinic locations – easily scalable for unlimited sites and extremely flexible to add and remove sites as needed'
    },
    {
      img: 'icon/BOOKINGS.png',
      ftitle: 'BOOKINGS',
      fcontent: 'Personalised booking page for your clinics, seamlessly integrated through to the clinical management software'
    },
    {
      img: 'icon/CLIENTS.png',
      ftitle: 'CLIENTS',
      fcontent: 'Keep all your patient data in one, secure place with safe access to contact information, personalised preferences and booking history'
    },
    {
      img: 'icon/PRACTITIONER CONSENTS.png',
      ftitle: 'PRACTITIONER CONSENTS',
      fcontent: 'Your Schedule 4 drug protocols and signed practitioner consents all accounted for within each patient record meeting all industry and medicolegal requirements'
    },
    {
      img: 'icon/PRESCRIBING DOCTORS.png',
      ftitle: 'PRESCRIBING DOCTORS',
      fcontent: 'Access to Instant Touch’s cosmetic prescribing doctors for scripting services through video call; trained and qualified in Neurotoxins, Dermal Filler, and Complications & Vascular Emergencies'
    },
    {
      img: 'icon/STOCKTAKE.png',
      ftitle: 'STOCKTAKE',
      fcontent: 'Automated stocktake of all Schedule 4 drugs ordered, prescribed, and used through treatments, as well as manual recalls, reconciling and auditing'
    },
    {
      img: 'icon/MARKETING.png',
      ftitle: 'MARKETING & REMINDERS',
      fcontent: 'Send automated marketing promotions and booking reminders through SMS and email to your client database'
    },
    {
      img: 'icon/REPORTING.png',
      ftitle: 'REPORTING',
      fcontent: 'Export multiple reports to track clients, staff, treatments, stock, sales, marketing and more...'
    },
    {
      img: 'icon/CLOUD.png',
      ftitle: 'CLOUD-BASED',
      fcontent: 'Completely cloud-based – no installation or downloads needed to access Instant Touch from wherever you are, whenever you need it'
    },
    {
      img: 'icon/COMPATIBLE.png',
      ftitle: 'COMPATIBLE',
      fcontent: 'Compatible and responsive across iPads, tablets, laptops and computers, working on all devices and touch sensors'
    },
    {
      img: 'icon/SUPPORT.png',
      ftitle: 'SUPPORT',
      fcontent: 'Dedicated training and technical support provided inclusive of daily backups and free data migration from other systems'
    },


  ],

  email: 'support@instanttouch.com.au',  
  address: '305 Del Sol, Sunnyvale, CA',
};
